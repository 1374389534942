import React, { useState } from "react"
import { httpPost } from "../utils/apiHook.js";


export const WeddingResponse = () => {

    const [isError, setIsError] = useState(false);
    const sendResponse = async (res) => {
        const ele = {
            firstname: document.getElementById("resp_firstname").value,
            lastname: document.getElementById("resp_lastname").value,
            mail: document.getElementById("resp_mail").value,
            response: res,
        }

        if (
            !!ele.firstname
            && !!ele.lastname
            && !!ele.mail
        ) {
            const res = await httpPost(null, '/api/responses', ele).catch(err => err);

            setIsError(!(res.status === true));
        }
        else {
            setIsError(true);
        }
    }

    return (
        <>
            <div className="ZusageOderAbsage">
                <h2>Rückmeldung</h2>
            </div>
            <div className="responseForm">
                <label>Vorname</label>
                <input id="resp_firstname" />
                <label>Nachname</label>
                <input id="resp_lastname" />
                <label>E-Mail</label>
                <input id="resp_mail" />
                <div className="button-group">
                    <button onClick={() => sendResponse("yes")}>Ich sage zu</button>
                    {
                        isError &&
                        <label>Fehler - Bitte Daten prüfen!</label>
                    }
                    <button onClick={() => sendResponse("no")}>Ich sage ab</button>
                </div>
            </div>
        </>
    )
}