import React, { useEffect, useState } from 'react';
import { animationInterval } from '../utils/animationHelper.js';

export const CountDown = ({ finalDate }) => {

    const [remaining, setRemaining] = useState(null);

    useEffect(() => {
        const controller = new AbortController();

        animationInterval(
            1000,
            controller.signal,
            () => {
                const Difference_In_Time = finalDate.getTime() - new Date().getTime();

                setRemaining({
                    days: Math.round(Difference_In_Time / (1000 * 60 * 60 * 24)),
                    hours: Math.round(Difference_In_Time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)),
                    minutes: Math.round(Difference_In_Time % (1000 * 60 * 60) / (1000 * 60)),
                    seconds: Math.round(Difference_In_Time % (1000 * 60) / 1000)
                })
            }
        );

        return () => {
            controller.abort();
        }
    })
    return (
        <>
            <div className="countdown-header">
                <div>
                    <h2>Countdown</h2>
                    <h3>
                        {
                            finalDate
                                ?.toLocaleDateString(
                                    "de-DE",
                                    {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }
                                )
                        }
                    </h3>
                </div>
                {
                    (
                        remaining &&
                        <table>
                            <tbody>
                                <tr>
                                    <th>{remaining.days}</th>
                                    <th>{remaining.hours}</th>
                                    <th>{remaining.minutes}</th>
                                    <th>{remaining.seconds}</th>
                                </tr>
                                <tr>
                                    <th>Tage</th>
                                    <th>Stunden</th>
                                    <th>Minuten</th>
                                    <th>Sekunden</th>
                                </tr>
                            </tbody>
                        </table>
                    )
                    ||
                    <h2>
                        bald ist es so weit
                    </h2>
                }
            </div>
        </>
    );
}