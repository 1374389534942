import React from 'react';

import './App.css';
import { PhotoContent } from './components/PhotoContent';
import { Welcome } from './components/Welcome';
import { CountDown } from './components/CountDown';
import { WeddingResponse } from './components/WeddingResponse';

export const App = () => {
    return (
        <div className="gridRoot">
            <Welcome />
            <div>
                <h2>
                    Herzlich Willkommen auf unsere Hochzeitswebseite!
                    <br />
                    <br />
                    Schön, dass Ihr uns auf unserem großen Sprung in die Ehe mitbegleitet!
                </h2>
            </div>
            <PhotoContent />
            <div className="VorstellungsReihe">
                <div className="BautSpalte">
                    <h2>Braut</h2>
                    <div className="text-content">
                        Moin, ich bin Jella, bin 27 Jahre alt.
                        <br />
                        Meine Lieblingsfarbe ist grün und ich mag Wölfe.
                        <br />
                        Ich lese gerne Bücher.
                    </div>
                </div>
                <div className="BreutigamSpalte">
                    <h2>Breutigam</h2>
                    <div className="text-content">
                        Moin, ich bin Jonathan, bin 27 Jahre alt.
                        <br />
                        Meine Lieblingsfarbe ist blau und ich mag Löwen.
                        <br />
                        Ich programmiere gerne in meiner Freizeit.
                    </div>
                </div>
            </div>
            <div>
                <h2>Unsere Geschichte</h2>
            </div>
            <div className="Geschichte">
                <div className="text-content">
                    Im Jahr 2014 begann unsere erste Begegnung. Zuerst waren wir beide in der gleichen Gruppe und trafen zusammen als Bekannte und als Freunde von Freunden. Doch als wir unsere Beziehungsstatus änderten und die Corona Zeit kam, kamen auch die langen interessanten Spaziergänge durch die wunderschönen grünen Wälder. Wir merkten beide, dass zwischen uns etwas ganz Besonderes ist.
                    <br />
                    <br />
                    An einem sehr warmen und schwülen Tag kamen wir beide wieder auf die Idee einen Spaziergang zu machen. Auf unerklärlicher Weise sind wir durch den Kalkrieser Berg auf einen wunderschönen Sonnenuntergang zugelaufen. Beim Sonnenuntergang sprangen wir beide in ein püllewarmen Teich rein und zeigten unsere Gefühle mit einem Kuss.
                    <br />
                    <br />
                    Erst am 03.07.2021 konnten sich durch weitere intensive Gespräche, wo die Spaziergänge durch Wälder und Feldwege gingen und nach einem Abend unter guten Freunden in Münster, eine Liebesgeschichte beginnen.
                    <br />
                    <br />
                    Seitdem ist die Anzahl der Spaziergänge gesunken, doch die Liebe und das Vertrauen ist gewachsen. Die Entscheidung einen gemeinsamen Weg zu gehen, hat gezeigt, dass nach einem Jahr zusammen sein, mehr möchte. So bin ich am 30.01.2022 zu Jonathan in die Wohnung mit eingezogen. Der Alltag gemeinsam zu bewältigen, bereichert unser Leben mit viel Liebe und Freude.
                    <br />
                    <br />
                    Mit vielen schönen gemeinsamen Erinnerung über 9 Jahren als Bekannte, als Vertraute und als Freunde stand am 01.07.2023 eine Frage gerichtet, die den kunterbunten Tag noch ereignisreicher gemacht hatte – er hat “JA” gesagt.
                    <br />
                    <br />
                    Unsere Liebe ist wie der Wald, durch den wir so oft spaziert sind – tief verwurzelt und immer weiter wachsend.
                </div>
            </div>
            <div className="VorstellungTrauzeugen">
                <div className="BautSpalte">
                    <h2>Trauzeugin</h2>
                    <div className="text-content">
                        Moin, ich bin Julia, bin 27 Jahre alt.
                        <br />
                        Ich bin die Zwillingsschwester von Jella. 
                    </div>
                </div>
                <div className="BreutigamSpalte">
                    <h2>Trauzeuge</h2>
                    <div className="text-content">
                        Moin, ich bin Max, bin 25 Jahre alt.
                        <br />
                        Ich bin der beste Freund von Jonathan.
                    </div>
                </div>
            </div>
            <CountDown finalDate={new Date('2025-07-04T11:00:00')} />
            <div className="address-wedding">
                <h2>Trauung Adresse</h2>
                <p>
                    Altes Irgendwas

                    49439 Steinfeld
                </p>
            </div>
            <div className="Ablauf">
                <h2>Party Trauung</h2>
                <ol>
                    <li>Ablauf 1</li>
                    <li>Ablauf 1</li>
                    <li>Ablauf 1</li>
                    <li>Ablauf 1</li>
                </ol>
            </div>
            <CountDown finalDate={new Date('2025-07-04T14:00:00')} />
            <div className="address-coffee">
                <h2>Kaffe Kuchen Adresse</h2>
                <p>
                    Alte Hofburg
                    Große Straße
                    49401 Damme
                </p>
            </div>
            <div className="Ablauf">
                <h2>Kuchen Ablauf</h2>
                <ol>
                    <li>Ablauf 2</li>
                    <li>Ablauf 2</li>
                    <li>Ablauf 2</li>
                    <li>Ablauf 2</li>
                </ol>
            </div>
            <CountDown finalDate={new Date('2025-07-05T17:00:00')} />
            <div className="address-party">
                <h2>Party Adresse</h2>
                <p>
                    Saalbetrieb Fangmann
                    49401 Damme
                </p>
            </div>
            <div className="Ablauf">
                <h2>Party Ablauf</h2>
                <ol>
                    <li>Ablauf 3</li>
                    <li>Ablauf 3</li>
                    <li>Ablauf 3</li>
                    <li>Ablauf 3</li>
                </ol>
            </div>
            <WeddingResponse />
            <div className="address-party">
                <h2>Unterkunft</h2>
                <p>
                    Hotel Adresse
                    ....
                </p>
            </div>
            <div className="Tischplanung">
                <h2>Tischplanung</h2>
                <p>noch unbekannt</p>
            </div>
            <div className="FAQ">
                <h2>FAQ</h2>
                <p>noch Fragen ???</p>
            </div>
        </div>
    );
}
