import React from 'react';

export const Welcome = () => {
    return (
        <div className="welcome-header">
            <h1>
                Jella und Jonathan heiraten!
                <div className="hearts-line">
                    <div className="heart" />
                    <div className="heart" />
                    <div className="heart" />
                </div>
                Am 4. Juli 2025 ist es endlich soweit!
            </h1>
        </div>
    );
}