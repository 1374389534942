
export const httpGet = (accessToken, apiEndpoint, searchQuery = {}, isJson = true, signal) => {

    const options = {
        signal,
        method: "GET",
        cache: "no-cache",
        headers: {
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
            'Content-Type': "application/json"
        },
    };

    return fetch(
        apiEndpoint + getQueryString(searchQuery),
        options
    )
        .then(async (response) => {
            if (response.ok) {
                return await (isJson ? response.json() : response.text());
            }
            else {
                const msg = await response.text();
                throw new Error(`${response.status} - ${response.statusText} ${(msg ? '(' + msg + ')' : '')}`)
            }
        })
};

export const httpPost = (accessToken, apiEndpoint, bodyData = {}, searchQuery = {}, isJson = true) => {

    const options = {
        method: "POST",
        cache: "no-cache",
        headers: {
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
            'Content-Type': "application/json"
        },
        body: bodyData ? JSON.stringify(bodyData) : null
    };

    return fetch(
        apiEndpoint + getQueryString(searchQuery),
        options
    )
        .then(async (response) => {
            if (response.ok) {
                return isJson ? response.json() : response.text();
            }
            else {
                const msg = await response.text();
                throw new Error(`${response.status} - ${response.statusText} ${(msg ? '(' + msg + ')' : '')}`)
            }
        })
};


export const getQueryString = (searchQuery) => {

    let params = new URLSearchParams();

    Object
        .keys(searchQuery)
        .forEach(key => {
            if (Array.isArray(searchQuery[key])) {
                searchQuery[key]
                    .forEach(ele => {
                        params.append(key, ele)
                    })
            }
            else {
                params.append(key, searchQuery[key])
            }
        });

    return params.size ? `?${params.toString()}` : "";
}